<template>
  <router-view/>
</template>
<script>
  import {baseClassList} from '@/service/api.js'
  export default {
    name: 'App',
    methods: {

    },
    mounted() {
      // baseClassList({}).then(res => {
      //     state.baseDetail = res
      // })
    }
  }
</script>

<style lang="scss">
</style>
