<template>
    <div class="reg__content">
        <van-nav-bar title="完善信息" :border="false" left-arrow @click-left="openReplace('/login')"/>
        <div class="login__box">
            <div class="bg">
                <img src="@/assets/image/logo.png"/>
            </div>
            <van-form @submit="onSubmit" class="login__input auto">
                <van-field
                        class="field__style border-rad"
                        v-model="nickname"
                        name="nickname"
                        clearable
                        type="text"
                        label="姓名"
                        placeholder="请输入姓名"
                />
                <van-field
                        class="field__style"
                        @click="showPickerFun"
                        clickable
                        readonly
                        name="picker"
                        v-model="classInfo.name"
                        right-icon="arrow"
                        is-link="true"
                        label="部门"
                        placeholder="请选择部门" arrowDirection="right"/>
<!--                <van-field-->
<!--                        class="field__style border-rad"-->
<!--                        v-model="mobile"-->
<!--                        name="mobile"-->
<!--                        clearable-->
<!--                        type="tel"-->
<!--                        label="手机号"-->
<!--                        placeholder="请输入手机号"-->
<!--                />-->
<!--                <van-field-->
<!--                        class="field__style box__align&#45;&#45;center"-->
<!--                        style="padding: 13px 18px"-->
<!--                        v-model="code"-->
<!--                        name="code"-->
<!--                        type="number"-->
<!--                        clearable-->
<!--                        label="验证码"-->
<!--                        placeholder="请输入验证码"-->
<!--                >-->
<!--                    <template #button>-->
<!--                        <van-button size="small" type="primary" round color="#CA4C36" class="code__button"-->
<!--                                    @click="getCode()" v-if="!isCode">获取验证码-->
<!--                        </van-button>-->
<!--                        <van-button size="small" type="primary" round color="#F5F5F5" class="code__button time" v-else>-->
<!--                            剩余 {{time}} 秒-->
<!--                        </van-button>-->
<!--                    </template>-->
<!--                </van-field>-->
                <div>
                    <van-button class="submit__button" round block type="primary" native-type="submit" color="#CA4C36" :loading="isSave">
                        确定
                    </van-button>
<!--                    <div class="login__reg" @click="openItems('login')">已有账号登录，去登录</div>-->
                </div>
            </van-form>
        </div>
        <van-popup v-model:show="showPicker" position="bottom">
            <van-search placeholder="请输入部门名称" v-model="classSearch" show-action @search="searchFun">
                <template #action>
                    <div @click="searchFun">搜索</div>
                </template>
            </van-search>
            <van-picker
                    :default-index="defaultIndex"
                    :columns="classList"
                    :columns-field-names="classListFieldName"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
            />
        </van-popup>
    </div>
</template>

<script>
    import {reactive, toRefs} from 'vue'
    import {useRouter} from "vue-router";
    import {baseClassList, baseSms, baseRegister, userEdit} from '@/service/api.js'
    import {isPhone} from '@/libs/tools.js'
    import {Toast} from "vant";

    const TIME = 59
    export default {
        setup() {
            const router = useRouter()
            const state = reactive({
                defaultIndex: 0,
                classSearch: '',
                classList: [],
                allClassList: [],
                classListFieldName: {
                    text: 'name',
                },
                classInfo: {
                    id: '',
                    name: ''
                },
                nickname: '',
                mobile: '',
                code: '',
                isCode: false,
                time: TIME,
                showPicker: false,
                isSave: false,
                timer: 0
            })
            // 点击跳转
            const openItems = (path, query) => {
                router.push({path, query})
            }
            // 点击返回
            const close = () => {
                router.go(-1)
            }
            const openReplace = (path, query) => {
                router.replace({path, query})
            }

            // 获取所属部门
            baseClassList().then(res => {
                state.allClassList = res
                state.classList = res
            })
            // 选择所属部门
            const onConfirm = (e) => {
                state.classInfo = e
                state.showPicker = false
            }
            const showPickerFun = () => {
                state.showPicker = true
                state.classSearch = ''
            }
            const searchFun = () => {
                state.classList.map((v,index) => {
                    if(state.classSearch){
                        if (~v.name.indexOf(state.classSearch)){
                            state.defaultIndex = index
                        }
                    }
                })
            }
            // 点击提交
            const onSubmit = (e) => {
                // const {mobile, code, nickname} = e
                const {nickname} = e
                if (!nickname) {
                    Toast('请输入姓名')
                    return false
                }
                if (!state.classInfo.id) {
                    Toast('请选择部门')
                    return false
                }
                // if (!isPhone(mobile)) {
                //     Toast('请输入正确的手机号')
                //     return false
                // }
                // if (!code) {
                //     Toast('请输入验证码')
                //     return false
                // }
                state.isSave = true
                userEdit({nickname, class_id: state.classInfo.id}).then(res => {
                    state.isSave = false
                    Toast('保存成功')
                    setTimeout(() => {
                        openItems('/list')
                    }, 800)
                }, fail => {
                    state.isSave = false
                })
                // baseRegister(Object.assign(e, {class_id: state.classInfo.id, noLoading: true})).then(res => {
                //     clearInterval(state.timer)
                //     Toast('注册成功')
                //     state.isSave = false
                //     localStorage.token = res.token
                //     setTimeout(() => {
                //         openItems('list')
                //     }, 1000)
                // }, fail => {
                //     state.isSave = false
                // })
            }
            // 获取验证码
            const getCode = () => {
                if (!isPhone(state.mobile)) {
                    Toast('请输入正确的手机号')
                    return false
                }
                if (state.isCode) {
                    return
                }
                state.isCode = true
                baseSms({type: 1, mobile: state.mobile}).then(res => {
                    Toast('发送成功')
                    state.timer = setInterval(() => {
                        if (state.time > 0) {
                            state.time--
                        } else {
                            state.isCode = false
                            state.time = TIME
                            clearInterval(state.timer)
                        }
                    }, 1000)
                }, fail => {
                    state.isCode = false
                })
            }

            return {
                ...toRefs(state),
                openItems,
                close,
                onConfirm,
                getCode,
                onSubmit,
                openReplace,
                showPickerFun,
                searchFun
            }
        }
    }
</script>
<style lang="scss" scoped>
    .reg__content {
        height: 100%;

        .login__box {
            height: calc(100% - 46px);
            display: flex;
            flex-direction: column;

            .login__input {
                background: #fff;
                border-radius: 8px;
                margin-top: -35px;
                margin-bottom: 13px;
                height: calc(100% - 13px);
            }

            .border-rad {
                border-radius: 8px 8px 0 0;
            }
        }

        .bg {
            background: url("~@/assets/image/login_bg.png") no-repeat;
            height: 143px;
            background-size: 100% 100%;
            text-align: center;

            span {
                font-size: 34px;
                color: #fff;
                font-family: 'SimSun';
            }

            img {
                width: 102px;
                height: 38px;
                display: block;
                margin: 0 auto;
                margin-top: 18px;
            }
        }

        .login__reg {
            font-size: 12px;
            color: #007AFF;
            text-align: center;
            margin-top: 12px;
        }

        .field__style {
            padding: 18px;

            .van-field__label {
                width: 46px;
            }

            &::after {
                left: 75px;
            }
        }

        .submit__button {
            height: 46px;
            width: 85%;
            margin: 0 auto;
            margin-top: 44px;
        }

        .code__button {
            padding: 0 14px;
            box-sizing: border-box;
            min-width: 90px;
        }
    }
</style>

