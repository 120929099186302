import request from './request.js'
export const baseUpload = (params) => request({ url: '/api/base/upload', method: 'post', type: 'file'}, params) // 上传图片获取URL
export const baseClassList = (params) => request({ url: '/api/base/classList', method: 'get'}, params) // 部门列表
export const userDetail = (params) => request({ url: '/api/user/detail', method: 'POST'}, params) // 个人信息
export const userEdit = (params) => request({ url: '/api/user/edit', method: 'POST'}, params) // 编辑个人信息
export const baseLogin = (params) => request({ url: '/api/base/login', method: 'POST'}, params) // 登录
export const baseSms = (params) => request({ url: '/api/base/sms', method: 'POST'}, params) // 发送短信
export const baseRegister = (params) => request({ url: '/api/base/register', method: 'POST'}, params) // 注册
export const questionList = (params) => request({ url: 'api/question/groupList', method: 'get'}, params) // 问题列表
export const questionItemList = (params) => request({ url: '/api/question/itemList', method: 'get'}, params) // 获取题目
export const questionAnswer = (params) => request({ url: '/api/question/answer', method: 'POST'}, params) // 回答题目
export const questionReport = (params) => request({ url: '/api/question/report', method: 'get'}, params) // 获取答题报告
export const userRankingList = (params) => request({ url: '/api/user/rankingList', method: 'get'}, params) // 获取排名信息
