/**
 * @description版本迭代code
 * @param {string} code
 * @returns {console}
 */
export const isPhone = (phone) => {
    const myreg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(phone)) {
        return false;
    }
    return true;
}