<template>
    <div class="login__content">
        <van-nav-bar title="登录" :border="false"/>
        <div class="login__box">
            <div class="bg">
                <img src="@/assets/image/logo.png" />
            </div>
            <van-form @submit="onSubmit" class="login__input auto">
                <van-field
                        class="field__style border-rad"
                        v-model="mobile"
                        clearable
                        name="mobile"
                        type="tel"
                        label="手机号"
                        placeholder="请输入手机号码"
                />
                <van-field
                        class="field__style box__align--center"
                        style="padding: 13px 18px"
                        maxlength="4"
                        v-model="code"
                        name="code"
                        type="number"
                        clearable
                        label="验证码"
                        placeholder="请输入验证码"
                >
                    <template #button>
                        <van-button size="small" type="primary" round color="#CA4C36" class="code__button" @click="getCode()" v-if="!isCode">获取验证码 </van-button>
                        <van-button size="small" type="primary" round color="#F5F5F5" class="code__button time" v-else >剩余 {{time}} 秒</van-button>
                    </template>
                </van-field>
                <div>
                    <van-button class="submit__button" round block type="primary" native-type="submit" color="#CA4C36" :loading="isSave">
                        登录
                    </van-button>
<!--                    <div class="login__reg" @click="openItems('/register')">新用户注册</div>-->
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import {reactive, toRefs} from 'vue'
    import {useRouter} from "vue-router";
    import {baseLogin, baseSms, userDetail} from '@/service/api.js'
    import {isPhone} from '@/libs/tools.js'
    import { Toast } from 'vant';
    const TIME = 59
    export default {
        setup() {
            const state = reactive({
                isSave: false,
                isCode: false,
                time: TIME,
                mobile: '',
                code: '',
                timer: 0
            })
            // 点击跳转
            const router = useRouter()
            const openItems = (path, query) => {
                router.replace({path, query})
            }
            // 点击提交
            const onSubmit = (e) => {
                const {mobile,code} = e
                if (!isPhone(mobile)){
                    Toast('请输入正确的手机号')
                    return false
                }
                if (!code){
                    Toast('请输入验证码')
                    return false
                }
                state.isSave = true
                e.noLoading = true
                baseLogin(e).then(res => {
                    localStorage.token = res.token
                    userDetail().then(res => {
                        // 没有填写信息则跳转到注册页面
                        if(!res.class_id){
                            openItems('/register')
                        } else {
                            clearInterval(state.timer)
                            Toast('登录成功')
                            setTimeout(() => {
                                openItems('/list')
                            }, 800)
                        }
                    })

                }, fail => {
                    state.isSave = false
                })
            }
            // 获取验证码
            const getCode = () => {
                if (!isPhone(state.mobile)){
                    Toast('请输入正确的手机号')
                    return false
                }
                if(state.isCode){
                    return
                }
                state.isCode = true
                baseSms({type:2, mobile: state.mobile}).then(res => {
                    Toast('发送成功')
                    state.timer = setInterval(() => {
                        if(state.time > 0){
                            state.time--
                        } else {
                            state.isCode = false
                            state.time = TIME
                            clearInterval(state.timer)
                        }
                    }, 1000)
                }, fail => {
                    state.isCode = false
                })
            }

            return {
                ...toRefs(state),
                openItems,
                onSubmit,
                getCode
            }
        }
    }
</script>
<style lang="scss" scoped>
    .login__content{
        height: 100%;
        .login__box{
            height: calc(100% - 46px);
            display:flex;
            flex-direction:column;
            .login__input{
                background: #fff;
                border-radius: 8px;
                margin-top: -35px;
                margin-bottom: 13px;
                height: calc(100% - 13px);
            }
            .border-rad{
                border-radius: 8px 8px 0 0;
            }
        }
        .bg{
            background: url("~@/assets/image/login_bg.png") no-repeat;
            height: 143px;
            background-size: 100% 100%;
            text-align: center;
            span{
                font-size: 34px;
                color: #fff;
                font-family: 'SimSun';
            }
            img{
                width: 102px;
                height: 38px;
                display: block;
                margin: 0 auto;
                margin-top: 18px;
            }
        }
        .login__reg{
            font-size: 12px;
            color: #007AFF;
            text-align: center;
            margin-top: 12px;
        }
        .field__style{
            padding: 18px;
            .van-field__label{
                width: 46px;
            }
            &::after{
                left: 75px;
            }
        }
        .code__button{
            padding: 0 14px;
            box-sizing: border-box;
            min-width: 90px;
        }
    }
</style>

