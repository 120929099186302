import { createStore } from 'vuex'

export default createStore({
  state: {
    isPlay: true,
  },
  mutations: {
    /**
     * 更新播放状态状态
     * */
    togglePlay(state, isPlay) {
      state.isPlay = isPlay
    },
  },
  actions: {
  },
  modules: {
  }
})
