import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import List from '../views/List.vue'
import Subject from '../views/Subject.vue'
import Result from '../views/Result.vue'
import Edit from '../views/Edit.vue'
import My from '../views/My.vue'

const routes = [
  {
    path: '/',
    redirect: '/list',
    component: List
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/subject',
    name: 'Subject',
    component: Subject
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/edit',
    name: 'Edit',
    component: Edit
  },
  {
    path: '/my',
    name: 'My',
    component: My
  },

  // {
  //   path: '/save',
  //   name: 'SaveImage',
  //   component: () => import('../views/SaveImage.vue')
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
