<template>
    <div class="subject__content">
        <van-nav-bar :title="title" :border="false" left-arrow @click-left="openReplace('/list')"/>
        <template v-if="baseDetail.length > 0">
            <div class="subject__bg"></div>
<!--            animate__slideInRight-->
<!--            animate__fadeInRight-->
            <div class="subject__box animate__animated " style="margin-top: -40px;animation-delay: 0s" :class="[!isSure && (index != 0) ? 'animate__fadeInRight' : '']" >
                <div class="border__8 subject__item auto">
                    <div class="main2 box">
                        <div class="wrap1"></div>
                        <span class="txt4 flex1">{{Object.values(baseDetail[index].right).length > 1 ? '多选题' : '单选题'}}</span>
                        <div class="wrap2">
                            <span class="word3">{{index+1}}</span> <span class="word4">/</span>
                            <span class="word5">{{baseDetail.length}}</span>
                        </div>
                    </div>
                    <div>
                    <span class="infoBox1"
                    >{{index+1}}、<span v-html="baseDetail[index].title"></span></span
                    >
                        <span class="word6"
                              v-if="baseDetail[index].origin"
                        >来源：{{baseDetail[index].origin}}</span
                        >
                    </div>
                    <div class="subject__li">
                        <div class="mod4"
                             :class="{'chose': item.ischose, 'ok': item.ischose && isSure && baseDetail[index].choseResult == baseDetail[index].right, 'no': item.ischose && isSure && !(baseDetail[index].choseResult == baseDetail[index].right)}"
                             v-for="(item, ind) in baseDetail[index].subject" @click="choseItem(ind)">
                            <span class="word4">{{item.nick}}、{{item.name}}</span>
                            <div class="box1" v-if="isSure && item.right || isSure && !item.right"></div>
                        </div>
                    </div>
                    <div class="mod8">
                        <img
                                class="icon1"
                                referrerpolicy="no-referrer"
                                src="@/assets/image/remind.png"
                        />
                        <span class="remind" @click="lookTips = true">查看提示</span>
                    </div>

                </div>
                <!--答案解析-->
                <div class="border__8 subject__item auto answer__box" v-if="isSure && baseDetail[index]">
                    <div class="layer1">
                        <div class="section1">
                            <div class="wrap4"></div>
                            <span class="txt2">答案解析</span>
                        </div>
                        <span class="word3">正确答案：{{baseDetail[index].answer}}</span>
                        <span class="word4" v-html="baseDetail[index].notice"></span>
                    </div>
                </div>
            </div>
            <div class="bank_h"></div>
            <div class="subject__button">
                <van-button class="submit__button" v-if="isSure" round block type="primary" native-type="submit"
                            color="#CA4C36" @click="nextFun" :loading="isSubmit">
                    {{(index+1) < baseDetail.length ? '下一题' : '完成'}}
                </van-button>
                <van-button class="submit__button" v-else :disabled="isChose" round block type="primary"
                            native-type="submit" color="#CA4C36" @click="sureFun">
                    确定
                </van-button>
            </div>
        </template>
        <van-empty description="暂无题目内容"  v-if="baseDetail.length == 0"/>

        <van-popup
                class="subject__style"
                v-model:show="lookTips"
                closeable
                close-icon="close"
                position="bottom"
                :style="{ maxHeight: '70%',minHeight: '35%' }"
        >
            <div class="title">
                <div class="section1">
                    <div class="wrap4"></div>
                    <span class="txt2">提示</span>
                </div>
            </div>
            <div class="des" v-html="baseDetail[index].notice"></div>
        </van-popup>
    </div>
</template>

<script>
    import {reactive, toRefs} from 'vue'
    import {useRouter, useRoute} from "vue-router";
    import {questionItemList, questionAnswer} from '@/service/api.js'
    export default {
        setup() {
            const state = reactive({
                title: '周周练',
                isSubmit: false,
                lookTips: false,
                index: 0,
                baseDetail: [],
                isChose: true,
                isSure: false,
                choseObject: {},
                toAnswer: {
                    '1': 'A',
                    '2': 'B',
                    '3': 'C',
                    '4': 'D'
                }
            })
            const route = useRoute();
            const router = useRouter();
            const {id, title} = route.query;
            state.title = title
            // 点击跳转
            const openItems = (path, query) => {
                router.push({path, query})
            }
            // 点击返回
            const close = () => {
                router.go(-1)
            }
            const openReplace = (path, query) => {
                router.replace({path, query})
            }
            questionItemList({id}).then(res => {
                res.map(v => {
                    v.choseResult = null
                    let answer = []
                    Object.values(v.right).map(val => {
                        state.toAnswer[val] && (answer.push(state.toAnswer[val]))
                    })
                    v.answer = answer.join('、')
                    v.subject = [
                        {
                            name: v.option_a,
                            key: '1',
                            nick: 'A',
                            ischose: false,
                        },
                        {
                            name: v.option_b,
                            key: '2',
                            nick: 'B',
                            ischose: false,
                        },
                        {
                            name: v.option_c,
                            key: '3',
                            nick: 'C',
                            ischose: false,
                        },
                        {
                            name: v.option_d,
                            key: '4',
                            nick: 'D',
                            ischose: false,
                        },
                    ]
                    return v
                })
                state.baseDetail = res
            })
            // 点击选择题目
            const choseItem = (index) => {
                // 一旦点击确认不能选择题目
                if (state.isSure) {
                    return
                }
                state.isChose = true
                state.baseDetail.map((val, ind) => {
                    let result = ''
                    if (ind == state.index) {
                        val.subject.map((v, key) => {
                            v.right = false
                            if (index == key) {
                                v.ischose = !v.ischose
                            }
                            if (v.ischose) {
                                result = result.concat(v.key)
                                state.isChose = false
                            }
                            return v
                        })
                        val.choseResult = result
                    }
                    return val
                })
            }
            // 确定答案
            const sureFun = () => {
                if (state.isChose) {
                    return
                }
                state.isSure = true
            }
            // 下一题
            const nextFun = () => {
                if ((state.index + 1) < state.baseDetail.length) {
                    state.index++
                    state.isSure = false
                    state.isChose = true
                } else {
                    let answer = {}
                    state.baseDetail.map((val, ind) => {
                        answer[val.id] = val.choseResult
                    })
                    let param = {
                        group_id: id,
                        answer
                    }
                    state.isSubmit = true
                    questionAnswer(param).then(res => {
                        // 查看结果页面返回 直接返回列表
                        openItems('/result', {id, title})
                    }, fail => {
                        state.isSubmit = false
                    })
                }
            }


            return {
                ...toRefs(state),
                openItems,
                openReplace,
                choseItem,
                sureFun,
                nextFun,
                close
            }
        }
    }
</script>
<style lang="scss">
    .subject__content {
        .subject__style {
            .title {
                margin-left: 15px;
                margin-top: 5px;
            }

            .des {
                font-size: 13px;
                color: #666666;
                margin: 15px;
                margin-top: 10px;
                line-height: 23px;
            }
        }

        .border__8 {
            border-radius: 8px;
        }

        .subject__bg {
            height: 50px;
            background: #d24f34;
            border-radius: 0 0 15px 15px;
        }

        .subject__item {
            background: #fff;
            padding: 0 3%;
            padding-bottom: 16px;

            .main2 {
                padding-top: 10px;
            }

            .wrap1 {
                z-index: 46;
                width: 3px;
                height: 16px;
                background-color: rgba(202, 76, 54, 1);
                margin-top: 5px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
            }

            .txt4 {
                z-index: 45;
                width: 45px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 15px;
                line-height: 21px;
                text-align: left;
                margin-top: 2px;
                margin-left: 6px;
            }

            .wrap2 {
                font-size: 0;
            }

            .word3 {
                font-size: 18px;
                color: rgba(202, 76, 54, 1);
                line-height: 25px;
            }

            .word4 {
                font-size: 13px;
                color: rgba(153, 153, 153, 1);
                line-height: 25px;
            }

            .word5 {
                font-size: 13px;
                color: rgba(153, 153, 153, 1);
                line-height: 25px;
            }

            .infoBox1 {
                z-index: 38;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 14px;
                line-height: 27px;
                text-align: justify;
                align-self: center;
                margin-top: 13px;
            }

            .word6 {
                display: block;
                overflow-wrap: break-word;
                color: rgba(201, 139, 2, 1);
                font-size: 13px;
                line-height: 23px;
                text-align: justify;
                align-self: center;
                margin-top: 8px;
            }

            .subject__li {
                margin-top: 28px;
            }

            .mod4 {
                padding: 10px 15px;
                align-self: center;
                margin-top: 10px;
                justify-content: center;
                align-items: flex-start;
                display: -webkit-box;
                flex-direction: column;
                box-sizing: border-box;
                background: #F9F8F8;
                border-radius: 6px;
                box-shadow: 0 2px 4px #DDD6D6;
                -webkit-box-align: center;

                & > span {
                    display: block;
                    margin-right: 10px;
                }

                .word4 {
                    overflow-wrap: break-word;
                    color: rgba(68, 68, 68, 1);
                    font-size: 14px;
                    text-align: justify;
                    -webkit-box-flex: 1;
                }

                &.chose {
                    box-shadow: 0 2px 4px rgba(202, 76, 54, .3);
                    background: #CA4C36;

                    .word4 {
                        color: #fff;
                    }
                }

                &.ok {
                    box-shadow: 0 2px 4px #C2DCAE;
                    background: #E5F8D6;

                    .word4 {
                        color: #64B422;
                    }

                    .box1 {
                        width: 16px;
                        height: 16px;
                        background-image: url('~@/assets/image/sure.png');
                        background-repeat: no-repeat;
                        display: flex;
                        flex-direction: column;
                        box-sizing: border-box;
                        background-size: 100% 100%;
                    }
                }

                &.no {
                    box-shadow: 0 2px 4px #F9B9A7;
                    background: #FFEDE8;

                    .word4 {
                        color: #E52323;
                    }

                    .box1 {
                        width: 16px;
                        height: 16px;
                        background-image: url('~@/assets/image/false.png');
                        background-repeat: no-repeat;
                        display: flex;
                        flex-direction: column;
                        box-sizing: border-box;
                        background-size: 100% 100%;
                    }
                }
            }

            .mod8 {
                margin-top: 12px;
                flex-direction: row;
                display: flex;
                justify-content: flex-end;
            }

            .icon1 {
                width: 17px;
                height: 17px;
                margin-top: 1px;
                margin-right: 8px;
            }

            .remind {
                z-index: 53;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 122, 255, 1);
                font-size: 13px;
                line-height: 18px;
                text-align: right;
            }


        }

        .bank_h {
            height: 66px;
        }

        .subject__button {
            width: 100%;
            z-index: 72;
            height: 56px;
            background-color: rgba(255, 255, 255, 1);
            align-self: center;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: fixed;
            left: 0;
            bottom: 0;

            .submit__button {
                margin-top: 0;
                width: 80%;
            }

            /*.layer2 {*/
            /*    height: 46px;*/
            /*    border-radius: 23px;*/
            /*    background-color: rgba(202, 76, 54, 1);*/
            /*    width: 80%;*/
            /*    justify-content: center;*/
            /*    align-items: center;*/
            /*    display: flex;*/
            /*    flex-direction: column;*/
            /*    box-sizing: border-box;*/
            /*    font-size: 15px;*/
            /*    color: #fff;*/
            /*}*/
            /*.disabled{*/
            /*    background: #F9F8F8;*/
            /*    color: #444444;*/
            /*}*/


        }

        .section1 {
            z-index: auto;
            margin-left: 1px;
            flex-direction: row;
            display: flex;
            justify-content: start;
            padding-top: 11px;

            .wrap4 {
                z-index: 35;
                width: 3px;
                height: 16px;
                background-color: rgba(202, 76, 54, 1);
                margin-top: 3px;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
            }

            .txt2 {
                margin-left: 10px;
                z-index: 34;
                margin-left: 6px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 15px;
                line-height: 21px;
                text-align: left;
            }
        }

        .answer__box {
            margin-top: 10px !important;


            .layer2 {
                z-index: 74;
                height: 46px;
                border-radius: 23px;
                background-color: rgba(202, 76, 54, 1);
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
            }

            .word3 {
                z-index: 32;
                display: block;
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 14px;
                line-height: 27px;
                text-align: justify;
                align-self: center;
                margin-top: 15px;
            }

            .word4 {
                z-index: 76;
                display: block;
                overflow-wrap: break-word;
                color: rgba(102, 102, 102, 1);
                font-size: 13px;
                line-height: 23px;
                text-align: justify;
                align-self: center;
                margin-top: 7px;
            }

            .word5 {
                z-index: 75;
                width: 45px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 15px;
                line-height: 21px;
                text-align: center;
            }
        }
    }
</style>

