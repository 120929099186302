<template>
    <div class="list__content">
        <van-nav-bar title="列表" :border="false" fixed placeholder>
            <template #right>
                <img src="@/assets/image/list_user.png" class="right__user" @click="openItems('/my')">
            </template>
        </van-nav-bar>
        <div class="list__item auto" v-for="(item,index) in list" :key="index">
            <div class="month">{{index}}</div>
            <div class="box item" v-for="(it,ind) in item" :key="ind" @click="openItems('/subject', {id: it.id, title: it.title})">
                <div class="flex1">
                    <div class="title">{{it.title}}</div>
                    <div class="status" v-if="it.is_answer">已作答</div>
                    <div class="status no" v-else>未作答</div>
                </div>
                <div class="integral" v-if="it.score > 0">
                    <div class="num">+{{it.score}}</div>
                    <div>积分</div>
                </div>
            </div>
        </div>
        <van-empty description="暂无数据"  v-if="Object.values(list).length == 0"/>
    </div>
</template>

<script>
    import {reactive, toRefs, defineComponent} from 'vue'
    import {useRouter} from "vue-router";
    import {questionList, userDetail} from '@/service/api.js'

    export default defineComponent({
        name: 'list',
        setup() {
            const router = useRouter()
            const state = reactive({
                list: {}
            })
            // 获取个人信息
            userDetail().then(res => {
                if(!res.class_id){
                    openItems('/register')
                    return
                }
                questionList().then(res => {
                    state.list = res
                })
            })
            // 点击返回
            const openItems = (path, query) => {
                router.push({path, query})
            }


            return {
                ...toRefs(state),
                openItems,
            }
        }
    })
</script>
<style lang="scss" scoped>
    .list__content {
        height: 100%;
        /*content-visibility: auto;*/

        .right__user {
            width: 20px;
            height: 20px;
            display: block;
        }

        .list__item {
            .month {
                font-size: 15px;
                position: relative;
                padding: 10px 0;
                padding-left: 8px;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 12px;
                    width: 3px;
                    height: 16px;
                    display: block;
                    background: #CA4C36;
                }
            }

            .item {
                padding: 12px;
                padding-top: 0;
                background: #fff;
                border-radius: 8px;
                margin-bottom: 8px;

                .title {
                    font-size: 14px;
                    margin-bottom: 5px;
                    padding-top: 12px;
                }

                .status {
                    font-size: 10px;
                    border-radius: 2px;
                    border: 1px solid #64B422;
                    display: inline-block;
                    color: #64B422;
                    padding: 0 2px;

                    &.no {
                        color: #CA4C36;
                        border-color: #CA4C36;
                    }
                }

                .integral {
                    background: url("~@/assets/image/list_grade.png") no-repeat;
                    background-size: 100% 100%;
                    padding: 0 10px;
                    padding-top: 4px;
                    height: 43px;

                    .num {
                        font-size: 13px;
                    }

                    font-size: 10px;
                    color: #CA4C36;
                }
            }
        }
    }

</style>

