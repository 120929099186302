<template>
    <div class="my__content">
        <van-nav-bar title="我的" :border="false" left-arrow @click-left="close" class="the__bg" >
            <template #right>
                <img src="@/assets/image/out.png" class="out__login" @click="openReplace('/login')" >
            </template>
        </van-nav-bar>

        <img src="@/assets/image/my/head.png" class="my__bg" v-if="userInfo">
        <div class="my__box" v-if="userInfo">
            <div class="my__item box__align--center auto" @click="openItems('/edit')">
                <div class="my__head flex1 box__align--center">
                    <div><img :src="userInfo.avatar ? userInfo.avatar : defHead"></div>
                    <div>{{userInfo.nickname}}</div>
                </div>
                <div class="right">
                    <van-icon name="arrow" class="right__icon--r"/>
<!--                    <img src="@/assets/image/right.png">-->
                </div>
            </div>
            <div class="my__item box__align--center auto item">
                <div class="my__head flex1 box__align--center">
                    <div><img src="@/assets/image/my/jf.png"></div>
                    <div>我的积分</div>
                </div>
                <div class="number">{{userInfo.score}}积分</div>
            </div>
            <div class="my__item box__align--center auto item">
                <div class="my__head flex1 box__align--center">
                    <div><img src="@/assets/image/my/pm.png"></div>
                    <div>我在本部门的排名</div>
                </div>
                <div class="number" v-if="rankingList">第{{rankingList.ranking}}名</div>
            </div>
        </div>
        <div class="my__sort auto" v-if="rankingList">
            <div class="box__align--center head box__pack--center">
                <img src="@/assets/image/my/right.png">
                <div>部门排行榜</div>
                <img src="@/assets/image/my/left.png">
            </div>
            <div class="my__table" >
                <div class="my__th box__align--center">
                    <div class="tr__left">排名</div>
                    <div class="flex1">昵称</div>
                    <div class="tr__right">积分</div>
                </div>
                <div class="my__tr  box__align--center" v-for="(item, index) in rankingList.ranking_list">
                    <div class="tr__left">
                        <template v-if="index < 3">
                            <img :src="require(`@/assets/image/my/sort${index+1}.png`)" />
                        </template>
                        <div v-else style="width: 22px;text-align: center;">{{index+1}}</div>
                    </div>
                    <div class="flex1 head__img box__align--center">
                        <img :src="item.avatar ? item.avatar : defHead">
                        <div class="flex1 over">{{item.nickname}}</div>
                    </div>
                    <div class="tr__right">{{item.score}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {reactive, toRefs} from 'vue'
    import {useRouter} from "vue-router";
    import {baseClassList, userDetail, userRankingList} from '@/service/api.js'
    import defHead from '@/assets/image/def.png'
    export default {
        setup() {
            const router = useRouter()
            const state = reactive({
                userInfo: null,
                rankingList: null,
                defHead
            })
            // 获取个人信息
            userDetail().then(res => {
                state.userInfo = res
            })
            // 获取排行榜
            userRankingList().then(res => {
                state.rankingList = res
            })

            // 点击跳转
            const openItems = (path, query) => {
                router.push({path, query})
            }
            const openReplace = (path, query) => {
                router.replace({path, query})
            }
            // 点击返回
            const close = () => {
                router.go(-1)
            }

            return {
                ...toRefs(state),
                openItems,
                close,
                openReplace
            }
        }
    }
</script>
<style lang="scss" scoped>
    .my__content {
        padding-bottom: 3%;
        .out__login{
            width: 20px;
            height: 20px;
            display: block;
        }
        .my__bg {
            width: 100%;
        }

        .my__box {
            position: relative;
            z-index: 2;
            margin-top: -51px;
        }

        .my__item {
            border-radius: 8px;
            background: #fff;
            padding: 19px 3%;

            &.item {
                padding: 13px 3%;
                margin-top: 10px;

                img {
                    width: 25px;
                    height: 25px;
                    display: block;
                    margin-right: 10px;
                }

                .number {
                    font-size: 15px;
                    color: #CA4C36;
                }
            }
        }

        .my__head {
            font-size: 14px;

            img {
                object-fit: cover;
                width: 42px;
                height: 42px;
                border-radius: 50%;
                display: block;
                margin-right: 16px;
            }
        }

        .right {
            width: 16px;
            margin-right: -1%;

            img {
                display: block;
                width: 100%;
            }
        }

        .my__sort {
            margin-top: 10px;
            border-radius: 8px;
            background: #fff;

            .head {
                font-size: 14px;
                padding: 15px 0;
                & > div {
                    margin: 0 7px;
                }

                img {
                    width: 46px;
                    display: block;
                }
            }

            .my__table {
                padding: 0 3%;
                font-size: 14px;

                .my__th {
                    padding: 0 3%;
                    background: #F9F8F8;
                    border-radius: 2px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 12px;
                    color: #999999;
                    /*text-align: center;*/

                }

                .tr__left {
                    width: 30%;
                    text-align: left;

                    img {
                        width: 22px;
                        height: 20px;
                        display: block;
                    }
                }

                .tr__right {
                    width: 20%;
                    text-align: right;
                }

                .my__tr {
                    padding: 15px 3%;
                    border-bottom: 1px solid #E9E9E9;

                    .head__img {
                        img {
                            object-fit: cover;
                            width: 21px;
                            height: 21px;
                            display: block;
                            border-radius: 50%;
                            margin-right: 5px;
                        }
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }

            }
        }

    }
</style>

