<template>
    <div class="edit__content">
        <van-nav-bar title="我的信息" :border="false" left-arrow @click-left="close"/>
        <div class="my__box  auto" v-if="userInfo">
            <div class="my__item box__align--center">
                <div class="flex1">头像</div>
                <div class="head">
                    <van-uploader :after-read="afterRead" :multiple="false" :max-count="1">
                        <img :src="userInfo.avatar ? userInfo.avatar : defHead">
                    </van-uploader>
                </div>
                <div class="right">
                    <van-icon name="arrow" class="right__icon--r"/>
<!--                    <img src="@/assets/image/right.png">-->
                </div>
            </div>
            <van-field class="vant__input" v-model="userInfo.nickname" right-icon="arrow" is-link="true" label="昵称"
                       placeholder="请输入昵称" inputAlign="right" arrowDirection="right"/>
            <van-field class="vant__input" v-model="userInfo.mobile" type="tel" readonly  is-link="true" label="手机号"
                       placeholder="请输入手机号" inputAlign="right" arrowDirection="right"/>
            <van-field v-if="userInfo.classInfo" class="vant__input" @click="showPickerFun" clickable readonly name="picker" v-model="userInfo.classInfo.name" right-icon="arrow" is-link="true" label="所属部门"
                       placeholder="请选择所属部门" inputAlign="right" arrowDirection="right"/>
            <div>
                <van-button class="submit__button" round block type="primary" color="#CA4C36" :loading="isSave" @click="saveFun">保存</van-button>
            </div>
        </div>


        <van-popup v-model:show="showPicker" position="bottom">
            <van-search placeholder="请输入部门名称" v-model="classSearch" show-action @search="searchFun" >
                <template #action>
                    <div @click="searchFun">搜索</div>
                </template>
            </van-search>
            <van-picker
                    :default-index="defaultIndex"
                    :columns="classList"
                    :columns-field-names="classListFieldName"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
            />
        </van-popup>
    </div>
</template>

<script>
    import {reactive, toRefs} from 'vue'
    import {useRouter} from "vue-router";
    import {baseClassList, userDetail, baseUpload, userEdit} from '@/service/api.js'
    import { Toast } from 'vant';
    import defHead from '@/assets/image/def.png'
    export default {
        setup() {
            const router = useRouter()
            const state = reactive({
                defaultIndex: 0,
                defHead,
                isSave: false,
                classList: [],
                classSearch: '',
                classListFieldName: {
                    text: 'name',
                },
                userInfo: null,
                showPicker: false,
            })
            // 获取个人信息
            userDetail().then(res => {
                state.userInfo = res
                // 获取所属部门
                baseClassList({noLoading: true}).then(ret => {
                    ret.map((v,index) => {
                        if(v.id == res.class_id){
                            state.defaultIndex = index
                        }
                    })
                    state.classList = ret
                })
            })
            // 选择所属部门
            const onConfirm = (e) => {
                state.userInfo.classInfo = e
                state.showPicker = false
            }
            const showPickerFun = () => {
                state.showPicker = true
                state.classSearch = ''
            }
            const searchFun = (type) => {
                state.classList.map((v,index) => {
                    if(state.classSearch){
                        if (~v.name.indexOf(state.classSearch)){
                            state.defaultIndex = index
                        }
                    }
                })
            }
            // 上传图片API
            const baseUploadIMage = (formData) =>{
                baseUpload(formData).then(res => {
                    state.userInfo.avatar = res.url
                })
            }
            const dataURLtoFile = (dataurl, filename) => { // 将base64转换为file文件
                let arr = dataurl.split(',')
                let mime = arr[0].match(/:(.*?);/)[1]
                let bstr = atob(arr[1])
                let n = bstr.length
                let u8arr = new Uint8Array(n)
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n)
                }
                return new File([u8arr], filename, {type: mime})
            }
            // 上传图片
            const afterRead = (file) => {
                Toast.loading({
                    duration: 0,
                    message: '上传中...',
                    forbidClick: true,
                    loadingType: 'spinner',
                })
                // 大于3MB的图片都缩小像素上传
                // const sizeCount = 3145728
                const sizeCount = 2097152 // 2M
                if (file.file.size > sizeCount) {
                    let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
                    let context = canvas.getContext('2d')
                    let img = new Image()
                    img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
                    img.onload = () => {
                        const width = img.width / 2
                        const height = img.height / 2
                        canvas.width = width
                        canvas.height = height
                        context.drawImage(img, 0, 0, width, height)
                        file.content = canvas.toDataURL(file.file.type, 0.8) // 0.92为默认压缩质量
                        let files = dataURLtoFile(file.content, file.file.name)
                        let formData = new FormData()
                        formData.append('image', files)
                        baseUploadIMage(formData)
                    }
                } else { //直接上传
                    let formData = new FormData()
                    formData.append('image', file.file)
                    baseUploadIMage(formData)
                }
            }
            // 保存
            const saveFun = () => {
                const {nickname, avatar, classInfo} = state.userInfo
                if(!nickname){
                    Toast('请输入昵称')
                    return false
                }
                if(!classInfo || !classInfo.id){
                    Toast('请选择所属部门')
                    return false
                }
                const param = {
                    nickname,
                    avatar,
                    class_id: classInfo.id
                }
                state.isSave = true
                userEdit(param).then(res => {
                    state.isSave = false
                    Toast('保存成功')
                    setTimeout(()=>{
                        router.go(-1)
                    }, 800)
                    // 返回上一页
                }, fail => {
                    state.isSave = false
                })
            }
            // 点击返回
            const close = () => {
                router.go(-1)
            }
            // 点击跳转
            const openItems = (path, query) => {
                router.push({path, query})
            }

            return {
                ...toRefs(state),
                openItems,
                close,
                onConfirm,
                afterRead,
                saveFun,
                showPickerFun,
                searchFun,
            }
        }
    }
</script>
<style lang="scss" scoped>
    .edit__content {

        height: 100%;

        .my__box {
            height: 90%;
            background: #fff;
            margin-top: 10px;
            border-radius: 8px;
        }

        .my__item {
            margin: 0 3%;
            /*margin-right: 2%;*/
            height: 55px;
            border-bottom: 1px solid rgba(235, 237, 240, 0.5);

            .flex1 {
                font-size: 14px;
                color: #000000;
            }

            .head {
                margin-right: 4px;
                img {
                    width: 36px;
                    height: 36px;
                    display: block;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .right {
                width: 16px;
                margin-right: -1%;

                img {
                    display: block;
                    width: 100%;
                }
            }

            .des {
                font-size: 14px;
                color: #666666;
            }
        }

        .vant__input {
            padding: 13px 3%;
        }

    }


</style>

