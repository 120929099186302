<template>
    <div class="result__content">
        <van-nav-bar title="答题完成" :border="false" left-arrow class="the__bg" @click="openReplace('/list')"/>
        <div class="result__block" v-if="result">
            <div class="main4">
                <span class="info2">本次答题题目数</span> <span class="info3">{{result.count}}</span>
            </div>
        </div>
        <div class="result__number auto" v-if="result">
            <div class="main5 box">
                <div>
                    <div class="number">{{result.right_rate * 100}}</div>
                    <div>正确率(%)</div>
                </div>
                <div>
                    <div class="number">{{result.error_num}}</div>
                    <div>错题数</div>
                </div>
<!--                <div>-->
<!--                    <div class="number">{{result.error_num}}</div>-->
<!--                    <div>用时(分钟)</div>-->
<!--                </div>-->
                <div>
                    <div class="number">{{result.score}}</div>
                    <div>积分</div>
                </div>
            </div>
        </div>
        <div class="subject__button" @click="openReplace('/subject', {id: id, title: title})">再练一次</div>
        <div class="subject__button back" @click="openReplace('/list')">返回</div>

    </div>
</template>

<script>
    import {reactive, toRefs} from 'vue'
    import {useRoute, useRouter} from "vue-router";
    import {questionReport} from '@/service/api.js'
    export default {
        setup() {
            const route = useRoute();
            const router = useRouter();
            const {id, title} = route.query;
            const state = reactive({
                result: null,
                id,
                title,
            })
            // 获取答题报告
            questionReport({group_id: id}).then(res => {
                state.result = res
            })
            // 点击跳转
            const openItems = (path, query) => {
                router.push({path, query})
            }
            const openReplace = (path, query) => {
                router.replace({path, query})
            }
            // 点击返回
            const close = () => {
                router.go(-1)
            }

            return {
                ...toRefs(state),
                openItems,
                openReplace,
                close
            }
        }
    }
</script>
<style lang="scss" scoped>
    .result__content {
        .result__block {
            z-index: 30;
            height: 153px;
            background-image: url('~@/assets/image/result_head.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 100%;
            justify-content: flex-start;
            padding-top: 38px;
            align-items: flex-start;
            padding-left: 32px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            main4 {
                z-index: auto;
                width: 91px;
                height: 61px;
                display: flex;
                flex-direction: column;
            }

            .info2 {
                z-index: 290;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 13px;
                line-height: 27px;
                text-align: justify;
                align-self: center;
            }

            .info3 {
                z-index: 291;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 24px;
                line-height: 27px;
                text-align: left;
                align-self: flex-start;
                margin-top: 7px;
            }
        }

        .result__number {
            border-radius: 12px;
            background-color: rgba(255, 255, 255, 1);
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding-top: 18px;
            margin-top: -25px !important;
            padding-bottom: 18px;
            margin-bottom: 28px;
            .main5 {
                z-index: auto;
                line-height: 24px;

                .number {
                    font-size: 17px;
                    color: rgba(0, 0, 0, 1);
                    margin-bottom: 3px;
                }

                & > div {
                    width: 33%;
                    -webkit-box-flex: 1;
                    text-align: center;
                    color: rgba(153, 153, 153, 1);
                    font-size: 11px;
                }
            }


        }

        .subject__button {
            background-color: #CA4C36;
            height: 46px;
            border-radius: 23px;
            width: 80%;
            font-size: 15px;
            color: #fff;
            text-align: center;
            line-height: 46px;
            margin: 0 auto;
            box-sizing: border-box;
            margin-top: 15px;
            &.back{
                border: 1px solid #E9E9E9;
                background-color: #fff;
                color: #444444;
            }
        }
    }


</style>

