// import { createApp } from 'vue'
// import { Button } from 'vant';
// import 'lib-flexible/flexible'
// import App from './App.vue'
// // import vant from '@/libs/vant/index.js'
// import router from './router'
// import 'vant/lib/index.css'; // 全局引入样式
// createApp(App).use(store).use(router).mount('#app')
import { createApp } from 'vue'
import { Empty, Button, Toast, NavBar, Uploader, Image as VanImage,Lazyload ,Form, Field, Popup, Picker, Skeleton, Icon, Search} from 'vant';
import 'lib-flexible/flexible'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css'; // 全局引入样式
import animated from 'animate.css'
import './assets/index.css';
import store from './store'
const app = createApp(App) // 创建实例
app.use(animated)
app.use(Button)
app.use(Toast)
app.use(Uploader)
app.use(NavBar)
app.use(VanImage)
app.use(Form)
app.use(Field)
app.use(Popup)
app.use(Picker)
app.use(Empty)
app.use(Skeleton)
app.use(Icon)
app.use(Search)
app.use(Lazyload)
// app.use(Lazyload, {
//     loading: loadimage,
// });
app.use(store)
app.use(router)

app.mount('#app')