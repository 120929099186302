/**
 * 该文件统一封装xhr请求
 * Created by xinqiao
 */
import Axios from 'axios'
import {Toast} from 'vant';

// 请求时的拦截器
Axios.interceptors.request.use(config => {
        if (localStorage.token) {
            config.headers['token'] = localStorage.token
        }
        return config
    },
    error => {
        // 响应异常时做一些处理
        return Promise.reject(error)
    })

// 请求完成后的拦截器
Axios.interceptors.response.use(resp => {
        const {code, data, info} = resp.data
        if (code == 1) {
            return data
        } else if (code == 400 || code == 401 || code == 403) {
            localStorage.removeItem('token')
            Toast('登录信息已过期，请重新登录')
            window.location.href = '/login'
        } else {
            return Promise.reject(info)
        }
    },
    error => {
        // 异常
        return Promise.reject(error.resp)
    })

const httpServer = (opts, data) => {
    let params = opts.type == 'file' ? data : Object.assign({}, data)
    if(!params.noLoading){
        Toast.loading({
            duration: 0,
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
        })
    }
    let headers = {'Content-Type': opts.type == 'file' ? 'multipart/form-data' : 'application/json'}
    let defaultOpts = {
        method: opts.method,
        // baseURL: 'http://hkrm-api.shhk.gov.cn',
        baseURL: window.HOST_URL ? window.HOST_URL : 'http://hkh5-zzl-api.i234567.com',
        url: opts.url,
        params: params, // get
        data: params,   // post
        headers,
    }
    if (opts.method === 'get') {
        delete defaultOpts.data
    } else {
        delete defaultOpts.params
    }
    let promise = new Promise(function (resolve, reject) {
        Axios(defaultOpts).then((res) => {
            resolve(res)
            Toast.clear()
        }).catch((response) => {
            Toast(response || 'Error')
            reject(response || 'Error')
        })
    })
    return promise
}
export default httpServer
